export default (context) => {
  if (process.client) {
    const { $config } = context
    window.onNuxtReady(() => {
      const noscript = document.createElement('noscript')
      const gtmId = $config.googleTagManagerId
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id='${gtmId}'"></iframe>`
      document.body.insertBefore(noscript, document.body.firstChild)
    })
  }
}
